import React, { FC } from 'react';
import { Col, Container, Row } from 'react-bootstrap';
import { AboutSectionShapeImg, BodyText, Box, DisplayCenter, DisplayEnd, DotLine, HeadingTitle, TesxtRightWrapper, TitleDescription } from '../../../components/styledComponents';

interface IProps {
}

export const ImgTextSection: FC<IProps> = ({ }) => {
    return (
        <>
            <Container>
                <Box mt='40px'>
                    <Row>
                        <Col lg={6} >
                            <Box mb='20px'>
                                <BodyText textAlign='left' lineHeight='28px' textColor='#e47226' fontWeight='400' fontSize='14px' className='text-uppercase letter-space-1px' mb="10px">CAREER</BodyText>
                                <HeadingTitle>We Provide Services To Our Clients As Per Their Requirements</HeadingTitle>
                                <DotLine />
                            </Box>
                            <Box mb='45px'>
                                <BodyText fontSize='18px' fontWeight='500' mt="15px" mb="10px">
                                    In our experience, we at <b>Expert Soft Solution</b> follow our standard process of work; we believe that every client is unique,
                                    so every project is original. We don't simply fit our client into a template; you can buy "off the rack". Even though each
                                    of our clients has unique needs, goals and timelines, so we deal with them according to their requirements.
                                </BodyText>
                            </Box>
                            <Box mb='45px'>
                                <BodyText textColor='#000' fontSize='18px' fontWeight='500' mt="15px" mb="10px">
                                    There are numerous steps in the web site design and development process. From gathering initial requirements,
                                    creation of your Web Application and finally maintenance to keep your Web Application up to date and current.
                                </BodyText>
                            </Box>
                        </Col>
                        <Col lg={6} className='order-col-sm-2'>
                            <DisplayCenter>
                                <div className="middle-image">
                                    <img className="ls-is-cached lazyloaded img-fluid" src="./images/company/ourProcess/our_process.webp" alt="image" />
                                </div>
                            </DisplayCenter>
                        </Col>
                    </Row>
                </Box>
            </Container>
        </>
    );
}