

export const keyData: IKeysData[] = [
    {
        icon: './images/about/quality.webp',
        title: 'Quality',
        description: 'At Expert Soft Solution we follow a strict process procedure, which allows us to work productively with clients, saving time and maintaining the highest level of quality.'
    },
    {
        icon: './images/about/low-cost.webp',
        title: 'Low Cost',
        description: 'Can effectively offer lowest prices due to our network of resources across the globe.'
    },
    {
        icon: './images/about/quick-customized-solutions.webp',
        title: 'Quick and Customized Solutions',
        description: "We have the best strategy and business consultants in our team, this allows us to understand the client's exact needs and develop the perfect solution for them."
    }
]

export const CustomListingData: ICustomServiceData[] = [
    {
        title: 'End-To-End Software Product Development',
        description: 'We help you build a successful digital product right from ideation to the improvement, covering all aspects of modern software development to meet your needs.'
    },
    {
        title: 'Enterprise Software Development',
        description: 'Whether you want to create enterprise software from scratch or planning to improve what’s exist, we help you redefine your business to solve real-world problems.'
    },
    {
        title: 'Cloud-Based Solutions',
        description: 'Move your applications to the cloud and enjoy the benefits of scalability and flexibility. We offer cloud-native app development or help enterprises to move to the cloud.'
    },
    {
        title: 'Custom ERP',
        description: 'We understand what it takes to build a custom ERP application that helps improve efficiency, productivity and sales with the help of one collaborative platform.'
    },
    {
        title: 'SaaS (Software as a Service) Development',
        description: "Our technical skillset and years of experience help us create scalable, reliable and secure SaaS development as per your ever-changing business needs."
    },
    {
        title: 'Tailor made CRM',
        description: "Our decades-long experience and dedication to deliver the best solutions can help increase your customer base with powerful, secure and custom CRM development."
    }
]
export const CyberSecurityIconList: ICyberSecurity[] = [
    {
        title: 'Compliance Manager --- Regulatory Compliance Management Solution',
        description: 'The Compliance Manager module offers essential tools for the continuous management of regulatory mandates and compliance frameworks. It enables the management of controls, assessments, and audits, while also providing reporting on status, scoring, and deficiencies. This module provides real-time visibility into your compliance and risk program and supports trending over time to visualize improvements in remediation efforts.',
        icon: './images/services/cyber-security/cyber-security-icon-1.webp'
    },
    {
        title: 'Maturity Manager --- Cybersecurity Maturation Tracker',
        description: 'The Findings Manager allows for the tracking, tasking, and monitoring of identified compliance deficiencies. It offers various status reporting options to provide management with visibility into the work efforts required to meet and maintain compliance.',
        icon: './images/services/cyber-security/cyber-security-icon-2.webp'
    },
    {
        title: 'Risk Register Module --- Risk Management Tracker',
        description: 'Continuously monitor business, compliance, and third-party risks, documenting each risk, mitigation activities, and contingency planning. Categorize risks by groupings, business unit, potential impact, Manage and track Risk Register is could impact the and response strategy (Avoidance, Transferring, Mitigation, Acceptance) . actions required to maintain the current status for each identified risk. The crucial for successful organizational risk management, measuring how a risk organization.',
        icon: './images/services/cyber-security/cyber-security-icon-3.webp'
    },
    {
        title: 'TPRM Module --- Third-Party Risk Management Platform',
        description: 'A centralized platform for businesses to oversee and manage their third-party relationships, including monitoring the security posture of vendors and suppliers. It facilitates the delivery of automated risk assessments to identify high-risk vendors, enabling the prioritization of mitigation efforts. This system enhances the ability to manage third-party cybersecurity risks and safeguards against potential data breaches and other cyber threats by understanding the associated risk to your organization.',
        icon: './images/services/cyber-security/cyber-security-icon-4.webp'
    },
    {
        title: 'Policy Manager Module-- Policy Governance and Compliance Platform',
        description: 'Create, organize, and oversee policies, track attestations, and manage exceptions. Consolidate your repository of policies, procedures, and standards. Enhance collaboration among all policy stakeholders in a unified platform. Streamline the policy lifecycle with pre-designed templates, workflows, and instant reporting.',
        icon: './images/services/cyber-security/cyber-security-icon-5.webp'
    },
    {
        title: 'Incident Management Module---- Incident Response and Resolution Platform',
        description: 'Establish a uniform, repeatable, and efficient incident response process. Develop an internal framework outlining the necessary steps for each phase of incident response. Maintain and regularly update playbooks to ensure consistency among responders.',
        icon: './images/services/cyber-security/cyber-security-icon-6.webp'
    },

]

export const WebListingData: IWebServiceData[] = [
    {
        title: 'Planning',
        description: 'Gather all the information and plan the project, craft the idea for the website and CMS development.'
    },
    {
        title: 'Web Designing',
        description: 'Our front-end designers will craft a stunning and striking responsive website following the wireframe, UI/UX design to prototype.'
    },
    {
        title: 'Define Content',
        description: 'A website is incomplete without content. Thus, we will put informative content on the website and make it SEO-friendly.'
    },
    {
        title: 'Development',
        description: "After deciding the website's objectives, it is time for development. The process is the pre-final stage of the website."
    },
    {
        title: 'Testing',
        description: "It is essential to conduct a test to ensure that the website is 100% bug-free and you get a seamless, user-friendly website."
    },
    {
        title: 'Deployment',
        description: "After everything is covered, from design to deployment, it is time to launch the website."
    },
    {
        title: 'Post Deployment & Maintenance',
        description: "After the website's launch, we will provide all support and answer every query after the deployment."
    }
]

export const CloudListingData: ICloudServiceData[] = [
    {
        title: 'We use the Cloud to provide you with a strategic advantage',
        description: 'We understand that you want to get your product to the market ahead of the competition. We can support you to do this by providing a continuous integration and continuous delivery (CI/CD) process tailored to your needs, while keeping the infrastructure costs optimal.'
    },
    {
        title: 'We ensure reliability',
        description: 'Reliability is of paramount importance to your clients. We offer you Infrastructure as code, with Site Reliable Engineering (SRE), which gives you greater ease of usage, consistency and accountability.'
    },
    {
        title: 'We remove technical obstacles',
        description: 'We help you realize your business goals without worrying about obstacles. It doesn’t matter to us if you have a hundred or a million clients - we prepare your website for high traffic. Importantly we will you solve business problems quicker.'
    },
    {
        title: 'We leave more time for innovation',
        description: "Because our streamlined processes lead to improved efficiency, we are left with more time for proactively brainstorming and helping you to further improve your digital product. We give you the support you need to further drive your business forward."
    }
]

export const InfrastructureAsCodeList: ICloudFeatureData = {
    heading: 'Infrastructure as code',
    listData: [
        {
            listItem: 'Planning based on requirements',
        },
        {
            listItem: 'Construction of the first version of the architecture',
        },
        {
            listItem: 'Verification and modification',
        },
        {
            listItem: 'Rapid setup of multiple environments',
        },
        {
            listItem: 'Ensuring accountability, as the shape of your infrastructure is defined in the code',
        },
    ]
}
export const CDProcessesList: ICloudFeatureData = {
    heading: 'CI/CD processes',
    listData: [
        {
            listItem: 'Getting to know the project stack',
        },
        {
            listItem: 'Containerization of the services',
        },
        {
            listItem: 'Preparation of the process pipeline',
        },
        {
            listItem: 'Tests and analysis',
        },
    ]
}

export const QualityListingData: IQualityServiceData[] = [
    {
        title: 'We are all invested in quality',
        description: 'While we have people specifically responsible for ‘testing’, we ensure that everyone in the team is deeply invested in quality assurance and that it permeates every area of our work from processes through to the code-writing. This spreading of responsibility reduces risk to a minimum.'
    },
    {
        title: 'We help you avoid risks and the costs associated with them',
        description: 'If issues are addressed at the design stage, there is no need to change code further down the line, which is a key way of avoiding bugs and system errors, which are likely to be very expensive to correct later.'
    },
    {
        title: 'We start software testing from the very beginning',
        description: 'We begin testing from the very first requirement gathering stage because we understand that it’s more cost-efficient to address issues early. We build our testing strategy at the outset and prepare test scenarios before your app is ‘clickable.’'
    },
    {
        title: 'We get to know your product and context',
        description: "We believe that effective testing cannot happen if we don’t have a thorough understanding of your product and context. We take the time to dive deeply into your brief before properly planning testing to provide as much value as possible."
    }
]

export const WebAppTestingList: IQualityFeatureData = {
    heading: 'Web Application Testing',
    listData: [
        {
            listItem: 'Browser Compatibility Testing',
        },
        {
            listItem: 'Penetration Testing',
        },
        {
            listItem: 'Database Testing',
        },
        {
            listItem: 'Usability Testing',
        },
        {
            listItem: 'Cross Browser Compatibility Testing',
        },
        {
            listItem: 'Load Testing',
        },
        {
            listItem: 'Regression Testing',
        },
        {
            listItem: 'Functional Testing',
        },
    ]
}
export const MobileAppTestingList: IQualityFeatureData = {
    heading: 'Mobile Application Testing',
    listData: [
        {
            listItem: 'Multiple Device Testing',
        },
        {
            listItem: 'Resolution Testing',
        },
        {
            listItem: 'Platform/OS based Testing',
        },
        {
            listItem: 'UI/UX Testing',
        },
        {
            listItem: 'Compatibility Testing',
        },
        {
            listItem: 'Usability Testing',
        },
        {
            listItem: 'Regression Testing',
        },
        {
            listItem: 'Functional Testing',
        },
    ]
}

export const MobileListingData: IMobileServiceData[] = [
    {
        title: 'Native Mobile App Development',
        description: 'Our mobile app developers can build high-quality native apps for both Android and iOS systems aligned with your business and security requirements.'
    },
    {
        title: 'Hybrid Mobile App Development',
        description: 'Cross-platform apps that can work in different environments thanks to a unique blend of native and web app technologies.'
    },
    {
        title: 'Requirement Gathering',
        description: 'We follow the first and foremost priority of gathering requirements, resources and information to begin our project.'
    },
    {
        title: 'UI/UX Design',
        description: "We create catchy and charming designs with the latest tools of designing to make it a best user-friendly experience."
    },
    {
        title: 'Prototype',
        description: "After designing, you will get your prototype, which will be sent ahead for the development process for the product."
    },
    {
        title: 'Development',
        description: "Development of mobile application/web/blockchain started using latest tools and technologies with transparency."
    },
    {
        title: 'Quality Assurance',
        description: "Hyperlink values quality and provides 100% bug free application with no comptonization in it."
    },
    {
        title: 'Deployment',
        description: "After trial and following all processes, your app is ready to launch on the App store or Play Store."
    },
    {
        title: 'Support & Maintenance',
        description: "Our company offers you all support and the team is always ready to answer every query after deployment."
    }
]

export const ProductListingData: IMobileServiceData[] = [
    {
        title: 'Product development consulting',
        description: 'Tell us your idea and our experienced consultants help you discover complete roadmap and design entire architecture that fit your business requirement'
    },
    {
        title: 'Product Re-Engineering/Application Modernization',
        description: 'Make your legacy system data-driven, agile and automated using new-age technology and modern development approach like agile and DevOps'
    },
    {
        title: 'Enterprise Architecture',
        description: 'We plan, develop and implement entire enterprise systems and modernize existing systems ensuring improved productivity, reduced overhead and operational costs'
    },
    {
        title: 'Data Analytics and Predictive Analytics',
        description: "Become data-driven organization by integrating data analytics and business intelligence solutions to make drive operational efficiency and make data-informed decisions"
    },
    {
        title: 'Platform-based Product Development',
        description: "Meet ever-changing needs by developing flexible, scalable and customized platform-based products with the underlying technical architecture and embedded systems"
    },
    {
        title: 'Custom Software Engineering',
        description: "Our 10+ years of experience in developing solutions across the industry verticals enable us to build scalable, business-centric and digitally-enhanced products"
    },
    {
        title: 'Discovery Workshops',
        description: "Our experienced consultants help you validate your idea, define scopes, design initial roadmap with every aspect and outline business goals"
    },
    {
        title: 'Product Maintenance',
        description: "Our round-the-clock support and assistance at every stage of software development allow businesses to operate hassle-free, ensuring high-availability and accessibility"
    },
    {
        title: 'UI UX Engineering',
        description: "Stay ahead of the curve with interactive, appealing, responsive and enterprise-grade designs from wireframing to prototyping to interaction designs, ensuring the seamless user experience"
    },
    {
        title: 'SaaS Development',
        description: "We design, build, test and maintain SaaS applications using right technology from planning to scaling, delivering robust, secure and end-to-end SaaS application"
    },
    {
        title: 'Cloud Adoption',
        description: "Migrate your system to the cloud and avail benefits like optimized performance, speed, ability to scale and continuous support and reduced overhead"
    },
    {
        title: 'Product Testing',
        description: "Before deploying the product, our QA team performs various checks and test product thoroughly using modern automated tools and also manually test for performance, compatibility and user experience"
    },
    {
        title: 'Enterprise Mobility',
        description: "Leverage our enterprise mobility services to improve productivity, boost sales and enhance customer experience by automating the process, making data-driven decisions and optimizing business processes"
    }
]

export const DatabaseServiceListing: IDatabaseServiceData[] = [
    {
        title: 'Defining technical parameters, information architecture and timeline by identifying unique needs of an enterprise.',
    },
    {
        title: 'Reducing risk and enhancing efficiency ensuring continuous delivery by automating procedures.',
    },
    {
        title: 'Conceptual, logical and physical database design and development, including ER Modeling, relation schemas, etc.',
    },
    {
        title: 'Warning of impending bottlenecks and slowdowns through continuous monitoring of databases.',
    },
    {
        title: 'Identifying potential failures through stress-testing of database.',
    },
    {
        title: 'Prioritized list of suggested improvements by recommending cost-weighted and threat-rated solutions.',
    },
    {
        title: 'Ongoing monitoring from beginning to end.',
    },
    {
        title: 'Database maintenance and tuning.',
    },
    {
        title: 'Data storage rules and regulations for compliance auditing.',
    },
]

export const WebDesignsList: IDesigns[] = [
    {
        image: "./images/designs/webDesigns/brainstormer-bingo.webp",
        title: "Brainstormer Bingo"
    },
    {
        image: "./images/designs/webDesigns/nassau.webp",
        title: "Nassau"
    },
    {
        image: "./images/designs/webDesigns/a&c-brothers.webp",
        title: "A & C brothers"
    },
    {
        image: "./images/designs/webDesigns/hire-a-teacher.webp",
        title: "Hire a teacher"
    },
    {
        image: "./images/designs/webDesigns/gezendeve.webp",
        title: "Gezendeve"
    },
    {
        image: "./images/designs/webDesigns/medici-1.webp",
        title: "Medici 1"
    },
    {
        image: "./images/designs/webDesigns/medici-2.webp",
        title: "Medici 2"
    },
    {
        image: "./images/designs/webDesigns/medici-3.webp",
        title: "Medici 3"
    },
    {
        image: "./images/designs/webDesigns/trivia.webp",
        title: "Trivia"
    },
    {
        image: "./images/designs/webDesigns/webhost.webp",
        title: "Webhost"
    },
    {
        image: "./images/designs/webDesigns/host-4-you.webp",
        title: "Host 4 You"
    }
]

export const MobileAppsList: IDesigns[] = [
    {
        image: "./images/designs/mobileApps/99-field-goals.webp",
        title: "99 Field goals",
        linkUrl: "/footballapp"
    },
    {
        image: "./images/designs/mobileApps/aldon.webp",
        title: "Aldon",
        linkUrl: "/aldonapp"
    },
    {
        image: "./images/designs/mobileApps/fabrio.webp",
        title: "Fabrio",
        linkUrl: "/fabrioapp"
    },
    {
        image: "./images/designs/mobileApps/sparky.webp",
        title: "Sparky",
        linkUrl: "/sparkyapp"
    }
]

export const FootballApp: IMobileApp[] = [
    {
        image: "./images/designs/mobileApps/footballApp/football1.webp"
    },
    {
        image: "./images/designs/mobileApps/footballApp/football02.webp"
    },
    {
        image: "./images/designs/mobileApps/footballApp/football03.webp"
    },
    {
        image: "./images/designs/mobileApps/footballApp/football04.webp"
    },
    {
        image: "./images/designs/mobileApps/footballApp/football05.webp"
    },
    {
        image: "./images/designs/mobileApps/footballApp/football06.webp"
    },
    {
        image: "./images/designs/mobileApps/footballApp/football07.webp"
    },
    {
        image: "./images/designs/mobileApps/footballApp/football08.webp"
    },
    {
        image: "./images/designs/mobileApps/footballApp/football09.webp"
    },
    {
        image: "./images/designs/mobileApps/footballApp/football10.webp"
    },
    {
        image: "./images/designs/mobileApps/footballApp/football11.webp"
    },
    {
        image: "./images/designs/mobileApps/footballApp/football12.webp"
    },
    {
        image: "./images/designs/mobileApps/footballApp/football13.webp"
    },
    {
        image: "./images/designs/mobileApps/footballApp/football14.webp"
    },
    {
        image: "./images/designs/mobileApps/footballApp/football15.webp"
    },
    {
        image: "./images/designs/mobileApps/footballApp/football16.webp"
    },
    {
        image: "./images/designs/mobileApps/footballApp/football17.webp"
    },
    {
        image: "./images/designs/mobileApps/footballApp/football18.webp"
    },
    {
        image: "./images/designs/mobileApps/footballApp/football19.webp"
    },
    {
        image: "./images/designs/mobileApps/footballApp/football20.webp"
    },
    {
        image: "./images/designs/mobileApps/footballApp/football21.webp"
    },
    {
        image: "./images/designs/mobileApps/footballApp/football22.webp"
    },
    {
        image: "./images/designs/mobileApps/footballApp/football23.webp"
    },
    {
        image: "./images/designs/mobileApps/footballApp/football24.webp"
    }
]

export const AldonApp: IMobileApp[] = [
    {
        image: "./images/designs/mobileApps/aldonApp/aldon1.webp"
    },
    {
        image: "./images/designs/mobileApps/aldonApp/aldon2.webp"
    },
    {
        image: "./images/designs/mobileApps/aldonApp/aldon3.webp"
    }
]

export const SparkyApp: IMobileApp[] = [
    {
        image: "./images/designs/mobileApps/sparkyApp/sparky1.webp"
    },
    {
        image: "./images/designs/mobileApps/sparkyApp/sparky2.webp"
    },
    {
        image: "./images/designs/mobileApps/sparkyApp/sparky3.webp"
    },
    {
        image: "./images/designs/mobileApps/sparkyApp/sparky4.webp"
    },
    {
        image: "./images/designs/mobileApps/sparkyApp/sparky5.webp"
    },
    {
        image: "./images/designs/mobileApps/sparkyApp/sparky6.webp"
    },
    {
        image: "./images/designs/mobileApps/sparkyApp/sparky7.webp"
    },
    {
        image: "./images/designs/mobileApps/sparkyApp/sparky8.webp"
    },
    {
        image: "./images/designs/mobileApps/sparkyApp/sparky9.webp"
    },
    {
        image: "./images/designs/mobileApps/sparkyApp/sparky10.webp"
    },
    {
        image: "./images/designs/mobileApps/sparkyApp/sparky11.webp"
    },
    {
        image: "./images/designs/mobileApps/sparkyApp/sparky13.webp"
    },
    {
        image: "./images/designs/mobileApps/sparkyApp/sparky12.webp"
    }

]

export const FabrioApp: IMobileApp[] = [
    {
        image: "./images/designs/mobileApps/fabrioApp/fabrio1.webp"
    },
    {
        image: "./images/designs/mobileApps/fabrioApp/fabrio2.webp"
    },
    {
        image: "./images/designs/mobileApps/fabrioApp/fabrio3.webp"
    },
    {
        image: "./images/designs/mobileApps/fabrioApp/fabrio4.webp"
    },
    {
        image: "./images/designs/mobileApps/fabrioApp/fabrio5.webp"
    },
    {
        image: "./images/designs/mobileApps/fabrioApp/fabrio6.webp"
    },
    {
        image: "./images/designs/mobileApps/fabrioApp/fabrio7.webp"
    },
    {
        image: "./images/designs/mobileApps/fabrioApp/fabrio8.webp"
    },
    {
        image: "./images/designs/mobileApps/fabrioApp/fabrio9.webp"
    },
    {
        image: "./images/designs/mobileApps/fabrioApp/fabrio10.webp"
    },
    {
        image: "./images/designs/mobileApps/fabrioApp/fabrio11.webp"
    },
    {
        image: "./images/designs/mobileApps/fabrioApp/fabrio12.webp"
    },
]

export const GraphicsDesignList: IDesigns[] = [
    {
        image: "./images/designs/graphicsDesign/lust-force.webp",
        title: "Lust force"
    },
    {
        image: "./images/designs/graphicsDesign/imressions.webp",
        title: "Impressions"
    },
    {
        image: "./images/designs/graphicsDesign/blessings.webp",
        title: "Blessings"
    },
    {
        image: "./images/designs/graphicsDesign/chahpak.webp",
        title: "Chahpak"
    },
    {
        image: "./images/designs/graphicsDesign/design-expert-agency.webp",
        title: "Design expert agency"
    }
]

export const LogoDesignsList: IDesigns[] = [
    {
        image: "./images/designs/logoDesigns/brainstormerd.webp",
        title: "Brainstormer"
    },
    {
        image: "./images/designs/logoDesigns/bubbles.webp",
        title: "Bubblesfunny"
    },
    {
        image: "./images/designs/logoDesigns/business.webp",
        title: "Business Finance Icon"
    },
    {
        image: "./images/designs/logoDesigns/butterfly.webp",
        title: "Butterfly"
    },
    {
        image: "./images/designs/logoDesigns/cocoon.webp",
        title: "Cocoon"
    },
    {
        image: "./images/designs/logoDesigns/colorful.webp",
        title: "Colorful abstract icon"
    },
    {
        image: "./images/designs/logoDesigns/flowervally.webp",
        title: "Flowervally"
    },
    {
        image: "./images/designs/logoDesigns/foodphoto.webp",
        title: "Food Photo"
    },
    {
        image: "./images/designs/logoDesigns/gingko.webp",
        title: "Gingko"
    },
    {
        image: "./images/designs/logoDesigns/Global.webp",
        title: "Global"
    },
    {
        image: "./images/designs/logoDesigns/Gogreen.webp",
        title: "Go Green Leaves"
    },
    {
        image: "./images/designs/logoDesigns/Greenglove.webp",
        title: "Green Globe"
    },
    {
        image: "./images/designs/logoDesigns/Healthcare.webp",
        title: "Health Care"
    },
    {
        image: "./images/designs/logoDesigns/jagaurs.webp",
        title: "De Chipas Fc"
    },
    {
        image: "./images/designs/logoDesigns/Justdiet.webp",
        title: "Just Diet Herbal Tea"
    },
    {
        image: "./images/designs/logoDesigns/loopabstract.webp",
        title: "Loop Abstract Icon"
    },
    {
        image: "./images/designs/logoDesigns/lovemelody.webp",
        title: "Love Melody"
    },
    {
        image: "./images/designs/logoDesigns/nature.webp",
        title: "Nature"
    },
    {
        image: "./images/designs/logoDesigns/painting.webp",
        title: "Painting"
    },
    {
        image: "./images/designs/logoDesigns/paperboat.webp",
        title: "Paper Boat"
    },
    {
        image: "./images/designs/logoDesigns/papillion.webp",
        title: "Papillion"
    },
    {
        image: "./images/designs/logoDesigns/plum.webp",
        title: "Plum"
    },
    {
        image: "./images/designs/logoDesigns/recycle.webp",
        title: "Recycle"
    },
    {
        image: "./images/designs/logoDesigns/Royal.webp",
        title: "Royal"
    },
    {
        image: "./images/designs/logoDesigns/shopping.webp",
        title: "Shopping"
    },
    {
        image: "./images/designs/logoDesigns/slonandspa.webp",
        title: "Butterfly Slon & Spa"
    },
    {
        image: "./images/designs/logoDesigns/snag-bag.png",
        title: "Snag Bag"
    },
    {
        image: "./images/designs/logoDesigns/Soft-Fly.webp",
        title: "Soft Fly"
    },
    {
        image: "./images/designs/logoDesigns/Somecapd.webp",
        title: "Somecaps"
    },
    {
        image: "./images/designs/logoDesigns/sparroweye.webp",
        title: "Sparroweye"
    },
    {
        image: "./images/designs/logoDesigns/twoposts.webp",
        title: "Two Pearls"
    },
    {
        image: "./images/designs/logoDesigns/twostones.webp",
        title: "Two Stones"
    },
    {
        image: "./images/designs/logoDesigns/water-lilly.webp",
        title: "Waterlily"
    },
    {
        image: "./images/designs/logoDesigns/womans-face.webp",
        title: "Woman's face"
    },
    {
        image: "./images/designs/logoDesigns/Writting.webp",
        title: "Writing"
    },
    {
        image: "./images/designs/logoDesigns/zealcon.webp",
        title: "Zealcon"
    }
]

export const FlyerList: IDesigns[] = [
    {
        image: "./images/designs/flyer/Inner-beauty.webp",
        title: "Inner Beauty"
    },
    {
        image: "./images/designs/flyer/webbulber.webp",
        title: "Web buldoser"
    },
    {
        image: "./images/designs/flyer/Homeliving.webp",
        title: "Home Living"
    },
    {
        image: "./images/designs/flyer/cahaya.webp",
        title: "Cahaya"
    },
    {
        image: "./images/designs/flyer/Creationarea.webp",
        title: "Creation Area"
    },
    {
        image: "./images/designs/flyer/Easyisokay.webp",
        title: "Easy is Okay"
    },
    {
        image: "./images/designs/flyer/photographystudio.webp",
        title: "Photography Studio"
    },
    {
        image: "./images/designs/flyer/Creative-business.webp",
        title: "Creative business"
    }
]

export const BrochuresList: IDesigns[] = [
    {
        image: "./images/designs/brochures/the-pages.webp",
        title: "The pages"
    },
    {
        image: "./images/designs/brochures/callient-warmup2.webp",
        title: "Callient warmup 2"
    },
    {
        image: "./images/designs/brochures/callient-warmup1.webp",
        title: "Callient warmup 1"
    },
    {
        image: "./images/designs/brochures/euro-track.webp",
        title: "Euro track"
    },
    {
        image: "./images/designs/brochures/cocool.webp",
        title: "Cocool"
    },
    {
        image: "./images/designs/brochures/dark-side.webp",
        title: "Dark Side"
    },
    {
        image: "./images/designs/brochures/konika.webp",
        title: "Konika"
    },
    {
        image: "./images/designs/brochures/celebration-of-life.webp",
        title: "Celebration of life"
    },
]

export const CorporateIdentityList: IDesigns[] = [
    {
        image: "./images/designs/corporateIdentity/dimenzia.webp",
        title: "Dimenzia"
    },
    {
        image: "./images/designs/corporateIdentity/fortress.webp",
        title: "Fortress"
    },
    {
        image: "./images/designs/corporateIdentity/fitness.webp",
        title: "Fitness"
    },
    {
        image: "./images/designs/corporateIdentity/eraser-industries.webp",
        title: "Eraser Industries"
    },
    {
        image: "./images/designs/corporateIdentity/c11.webp",
        title: "C11"
    },
    {
        image: "./images/designs/corporateIdentity/eletech.webp",
        title: "Eletech"
    },
    {
        image: "./images/designs/corporateIdentity/driscolls.webp",
        title: "Driscolls"
    },
    {
        image: "./images/designs/corporateIdentity/c8.webp",
        title: "C8"
    },
    {
        image: "./images/designs/corporateIdentity/c7.webp",
        title: "C7"
    },
    {
        image: "./images/designs/corporateIdentity/temple.webp",
        title: "temple"
    },
    {
        image: "./images/designs/corporateIdentity/enzyme.webp",
        title: "Enzyme"
    },
    {
        image: "./images/designs/corporateIdentity/c4.webp",
        title: "C4"
    },
    {
        image: "./images/designs/corporateIdentity/plus.webp",
        title: "Plus"
    },
    {
        image: "./images/designs/corporateIdentity/deserve.webp",
        title: "Deserve"
    },
    {
        image: "./images/designs/corporateIdentity/c1.webp",
        title: "C1"
    },
]