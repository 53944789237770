import React, { FC } from 'react';
import { PageBanner } from '../../../components/pageBanner';
import { Container } from 'react-bootstrap';
import { CardMainSection } from './cardMainSection';
import { BodyText, Box, DisplayCenter, DotLine, HeadingTitle } from '../../../components/styledComponents';

interface IProps {
}

export const AspNetWebsites: FC<IProps> = ({ }) => {
    return (
        <>
            <PageBanner
                bannerImg="./images/projects/asp-websites/aspNet-banner.webp"
                pageTitle="Expert Soft Solution / React & .Net Core"
            />
            <Container fluid className='px-5 container-lg-p'>
                <DisplayCenter mb='40px' mt='60px'>
                    <Box className='text-center'>
                        <BodyText textAlign='center' textColor='#e47226' fontWeight='400' fontSize='14px' className='text-uppercase letter-space-1px' mb="10px">React & .Net Core</BodyText>
                        <HeadingTitle textAlign='center'>Our Portfolio</HeadingTitle>
                        <DisplayCenter>
                            <DotLine />
                        </DisplayCenter>
                    </Box>
                </DisplayCenter>
                <CardMainSection />
            </Container>
        </>
    );
}
