import React, { FC } from 'react';
import { PageBanner } from '../../../components/pageBanner';
import { FooterTop } from '../../../components/footerTop';
import { Container } from 'react-bootstrap';
import { MainSection } from './mainSection';
import { IconBoxSection } from './iconBoxSection';
import { CheckFeatureListSection } from './checkFeatureListSection';

interface IProps {
}

export const QualityAssurance: FC<IProps> = ({ }) => {
    return (
        <>
            <PageBanner
                bannerImg="./images/services/quality-assurance/quality-assurance-banner.webp"
                pageTitle="Expert Soft Solution / Quality Assurance"
            />
            <Container className='pt-5 pb-3'>
                <MainSection />
                <IconBoxSection />
            </Container>
            <CheckFeatureListSection />
            <FooterTop subTitle="Plan to Start a Project" title="Our Experts Ready to Help You" btnText="Contact Us" />
        </>
    );
}
