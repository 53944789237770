import React, { FC } from 'react';
import { Col, Container, Row } from 'react-bootstrap';
import { AboutSectionShapeImg, BodyText, Box, DisplayCenter, DisplayEnd, DotLine, HeadingTitle, TesxtRightWrapper, TitleDescription } from '../../../components/styledComponents';

interface IProps {
}

export const ImgTextSection: FC<IProps> = ({ }) => {
    return (
        <>
            <Container>
                <Row>
                    <Col lg={6} className='order-col-sm-2'>
                        <DisplayCenter>
                            <div className="middle-image">
                                <img className=" ls-is-cached lazyloaded" src="./images/company/career/career.webp" alt="image" />
                            </div>
                        </DisplayCenter>
                    </Col>
                    <Col lg={6} >
                        <Box mb='20px'>
                            <BodyText textAlign='left' lineHeight='28px' textColor='#e47226' fontWeight='400' fontSize='14px' className='text-uppercase letter-space-1px' mb="10px">CAREER</BodyText>
                            <HeadingTitle>Expert soft Solution</HeadingTitle>
                            <DotLine />
                        </Box>
                        <Box mb='45px'>
                            <BodyText textColor='#000' fontSize='18px' fontWeight='500' mt="15px" mb="10px">
                                We offers talented and motivated people the opportunity to work on latest programming technologies in a dynamic working environment.
                                Are you looking for a satisfying and growing professional place? We always encourage the people who have the urge of growth and knowledge.
                                We try to fulfill the mutual needs of the personnel and the organization.
                            </BodyText>

                        </Box>
                    </Col>
                </Row>
            </Container>
        </>
    );
}