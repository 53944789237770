export const Routes = {
  home: "/",
  about: "/about",
  contac: "/contact",
  customSoftwareDevelopment: "custom-software-development",
  webAppDevelopment: "web-app-development",
  cloudAndDevOps: "cloud-and-devops",
  qualityAssurance: "quality-assurance",
  mobileAppDevelopment: "mobile-app-development",
  productEngineering: "product-engineering",
  databaseAdministration: "database-administration",
  webSecurity: "web-security",
  cyberSecurity: "cyber-security",
  aspNetWebsites: "aspnetprojects",
  wordpressWebsites: "wordpressprojects",
  phpWebsites: "phpprojects",
  webApplication: "web-application",
  technologies: "technologies",
  career: "career",
  ourProcess: "ourprocess",
  outsourcingToPakistan: "outsourcingtopakistan",
  webDesigns: "webdesigns",
  mobileApps: "mobileapp",
  graphicsDesigns: "graficsdesigns",
  logoDesigns: "logodesigns",
  flyer: "flyer",
  brochures: "brochures",
  corporateIdentity: "corporate",
  footBallApp: "footballapp",
  aldonApp: "aldonapp",
  fabrioApp: "fabrioapp",
  sparkyApp: "sparkyapp"
}
