import React, { FC } from 'react';
import { Col, Row } from 'react-bootstrap';
import { BodyText, Box, DotLine, HeadingTitle, TesxtRightWrapper, TitleDescription } from '../../../components/styledComponents';

interface IProps {
}

export const MainSection: FC<IProps> = ({ }) => {
    return (
        <>
            <Row>
                <Col lg={6} sm={12} className='align-self-start'>
                    <Box className='pt-2'>
                        <Box mb='20px'>
                            <HeadingTitle>Cyber Security</HeadingTitle>
                            <DotLine />
                        </Box>
                        <Box mt='20px'>
                            <TitleDescription fontsize="22px">
                                Our SaaS software is designed to help organizations continuously manage cyber risks and provide
                                visibility to the executive suite. With our platform, you can utilize our patent pending Compliance and
                                Risk Tracker to identify, measure, prioritize, and remediate cyber risks. The software is populated based
                                on the results of the Cybersecurity Capacity and Maturity Assessment interviews and assessment
                                framework, allowing you to measure improvement to your cyber security maturation. Trust our SaaS
                                solution to keep your organization secure from cybersecurity risks.
                            </TitleDescription>
                        </Box>
                    </Box>
                </Col>
                <Col lg={6} sm={12}>
                    <div className="">
                        <img className="custom-software-img" src="./images/services/database-administration/database-administration.webp" alt="custom software development - Expert Soft Solution" />
                    </div>
                </Col>
            </Row>
        </>
    );
}