import React, { FC } from 'react';
import { Col, Row } from 'react-bootstrap';
import { BodyText, Box, DotLine, HeadingTitle } from '../../../components/styledComponents';

interface IProps {
}

export const MainSection: FC<IProps> = ({ }) => {
    return (
        <>
            <Row>
                <Col lg={6} sm={12} className='align-self-start'>
                    <Box className='pt-2'>
                        <Box mb='20px'>
                            <HeadingTitle> Database Administration </HeadingTitle>
                            <DotLine />
                        </Box>
                        <Box mt='20px'>
                            <BodyText textColor='#454545' fontSize='18px' fontWeight='500' mt="15px" mb="25px">
                                Aligning business performance and productivity with the growing and complex data portfolios has become an increasingly challenging task for enterprises.
                            </BodyText>
                            <BodyText textColor='#454545' fontSize='18px' fontWeight='500' mt="15px" mb="25px">
                                Expert Soft Solution has an experienced team of professional consultants who have in-depth technical and process expertise in architecting, configuring and managing complex database migrations, integration, rollouts and upgrades.
                            </BodyText>
                            <BodyText textColor='#454545' fontSize='18px' fontWeight='500' mt="15px" mb="25px">
                                Our certified consultants are trained in the deployment of state-of-the-art technologies like Microsoft SQL and help enterprises improve their current database management operations and reduce their Total Cost of Ownership.
                            </BodyText>
                        </Box>
                    </Box>
                </Col>
                <Col lg={6} sm={12}>
                    <div className="mb-1">
                        <img className="custom-software-img" src="./images/services/database-administration/database-administration.webp" alt="database administration - Expert Soft Solution" />
                    </div>
                </Col>
            </Row>
        </>
    );
}