import React, { FC } from 'react';
import { PageBanner } from '../../components/pageBanner';
import { ContactContent } from './contactContent';

interface IProps {
}

export const Contact: FC<IProps> = ({ }) => {
    return (
        <>
            <PageBanner
                bannerImg="./images/contact/contact-banner.webp"
                pageTitle="Contact Expert Soft Solution"
            />
            <ContactContent />
        </>
    );
}
