import React, { FC } from 'react';
import { BodyText, Box, DisplayCenter, FooterBottomWrapper } from '../../components/styledComponents';

interface IProps {
}

export const FooterBottom: FC<IProps> = ({ }) => {
    const currentDate = new Date();
    const currentYear = currentDate.getFullYear();

    return (
        <>
            <FooterBottomWrapper>
                <Box>
                    <DisplayCenter>
                        <BodyText fontSize='15px' textColor='#fff' lineHeight='35px'>© 2009-{currentYear} All Rights Reserved. Expert Soft Solution.</BodyText>
                    </DisplayCenter>
                </Box>
            </FooterBottomWrapper>
        </>
    );
}
