import React, { FC, useState } from 'react';
import { PageBanner } from '../../components/pageBanner';
import { BodyText, Box, DisplayCenter, DisplayStart } from '../../components/styledComponents';
import { Col, Container, Row } from 'react-bootstrap';
import { OurProcess } from '../home/ourProcess';
import FSLightbox from 'fslightbox-react';
import { WhoWeAreSection } from './whoWeAreSection';
import { KeyDifferentiatorSection } from './keyDifferentiatorSection';

interface IProps {
}

export const About: FC<IProps> = ({ }) => {
    const [toggler, setToggler] = useState<boolean>(false);

    return (
        <>
            <PageBanner
                bannerImg="./images/about/about-banner.webp"
                pageTitle="About Expert Soft Solution"
            />
            <Container fluid className='px-5 container-lg-p pt-5 pb-3'>
                <WhoWeAreSection />
                <KeyDifferentiatorSection />
                <OurProcess />
                <Box pt="35px" pb="22px">
                    <DisplayStart>
                        <Box>
                            <img src="./images/about/pseb-logo-transparent.webp" alt="pseb-logo-transparent" className='' />
                        </Box>
                        <Box ml="15px">
                            <BodyText fontSize='18px' fontWeight='500' mb="10px">
                                Expert Soft Solution is registered with Pakistan Software Export Board.
                            </BodyText>
                            <BodyText fontSize='18px' fontWeight='700'>
                                PSEB Reg. #:  Z-25-6783/22
                            </BodyText>
                        </Box>
                    </DisplayStart>
                    <DisplayCenter>
                        <img src="./images/about/pseb-certificate.webp" alt="pseb-certificate" className='pseb-certify mt-4' onClick={() => setToggler(!toggler)} />
                        <FSLightbox
                            toggler={toggler}
                            sources={[
                                './images/about/pseb-certificate.webp',
                            ]}
                        />
                    </DisplayCenter>
                </Box>
            </Container>

        </>
    );
}