import React, { FC } from 'react';
import { PageBanner } from '../../../components/pageBanner';
import { Container } from 'react-bootstrap';
import { FooterTop } from '../../../components/footerTop';
import { MainSection } from './mainSection';
import { InnerParaSection } from './innerParaSection';
import { PenetrationTestSection } from './penetrationTestSection';
import { DastTestSection } from './dastTestSection';
import { TwoFactorAuthenticateSection } from './twoFactorAuthenticateSection';

interface IProps {
}

export const WebsiteSecurity: FC<IProps> = ({ }) => {
    return (
        <>
            <PageBanner
                bannerImg="./images/services/web-security/web-security-banner.webp"
                pageTitle="Expert Soft Solution / Web Security"
            />
            <Container className='pt-5 pb-3'>
                <MainSection />
                <InnerParaSection />
                <PenetrationTestSection />
                <DastTestSection />
                <TwoFactorAuthenticateSection />
            </Container>
            <FooterTop subTitle="Plan to Start a Project" title="Our Experts Ready to Help You" btnText="Contact Us" />
        </>
    );
}
