import React, { FC } from 'react';
import { Col, Row } from 'react-bootstrap';
import { BodyText, Box, DotLine, HeadingTitle } from '../../../components/styledComponents';

interface IProps {
}

export const MainSection: FC<IProps> = ({ }) => {
    return (
        <>
            <Row>
                <Col lg={6} sm={12} className='align-self-start'>
                    <Box className='pt-2'>
                        <Box mb='20px'>
                            <HeadingTitle> Website Security </HeadingTitle>
                            <DotLine />
                        </Box>
                        <Box mt='20px'>
                            <BodyText textColor='#454545' fontSize='18px' fontWeight='500' mt="15px" mb="25px">
                                Authorization, data protection, non-repudiation. In some cases the best techniques to handle any errands are the most straightforward ones. You realize that you want to guard your site from the miscreants, yet when you adventure down the dark hole of site weaknesses, you will be confronted with complex ideas and tangled arrangements. In any case, there are essential prescribed procedures to follow for working on your site's security. The following are eight fundamental things that you can do to protect your site at the present time:
                            </BodyText>
                        </Box>
                        <Box mb='10px'>
                            <BodyText textColor='#0a0a0a' fontSize='20px' fontWeight='700' mb="15px">
                                Keep your software up-to-date
                            </BodyText>
                            <BodyText textColor='#454545' fontSize='18px' fontWeight='500' mt="15px">
                                It is crucial to keep all platforms or scripts you've installed up-to-date. Hackers aggressively target security flaws in popular web software and the programs need to be updated to patch security holes. It is important to maintain and update every software product you use.
                            </BodyText>
                        </Box>
                    </Box>
                </Col>
                <Col lg={6} sm={12}>
                    <div className="mb-1">
                        <img className="custom-software-img" src="./images/services/web-security/website-security.webp" alt="web app development - Expert Soft Solution" />
                    </div>
                </Col>
            </Row>
        </>
    );
}