import React, { FC } from 'react';
import { Col, Row } from 'react-bootstrap';
import { BodyText, Box, DisplayCenter, DisplayStart, DotLine, HeadingTitle } from '../../../components/styledComponents';

interface IProps {
}

export const DatabaseLifecycleSection: FC<IProps> = ({ }) => {
    return (
        <>
            <Row className='pt-4'>
                <Col lg={12} sm="12">
                    <Box mb='30px'>
                        <HeadingTitle className='text-center'> Database Lifecycle Management </HeadingTitle>
                        <DisplayCenter>
                            <DotLine />
                        </DisplayCenter>
                    </Box>
                    <Box mt='20px'>
                        <BodyText textColor='#454545' fontSize='18px' fontWeight='500' mt="15px" mb="25px" className='text-center'>
                            Database life cycle management enables enterprises to adapt to the changes that occur throughout the life cycle of the database allowing for enhanced efficiency, resource management, collaboration between teams, scalability and visibility.
                        </BodyText>
                        <BodyText textColor='#454545' fontSize='18px' fontWeight='500' mt="15px" mb="25px" className='text-center'>
                            Expert Soft Solution has a trained pool of database life cycle management experts who have vast experience in strategically deploying DLM strategies across various verticals.
                        </BodyText>
                    </Box>
                </Col>
            </Row>
        </>
    );
}