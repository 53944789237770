import React, { FC } from 'react';
import { Col, Container, Row } from 'react-bootstrap';
import { CgPacman } from 'react-icons/cg';
import { BodyText, Box, ContactInfoIcon, ContactInfoIconBox, ContactInfoWrapper, DisplayStart, DotLine, GetAQuoteFormBox, HeadingTitle, HeadingTopLabel } from '../../components/styledComponents';
import { FaEnvelope, FaHome, FaPhone } from 'react-icons/fa';
import { GetAQuoteForm } from '../../components/getAQuoteForm';

interface IProps {
}

export const ContactContent: FC<IProps> = ({ }) => {
    return (
        <>
            <Container fluid className='px-5 container-lg-p my-5'>
                <Row className='align-items-strech'>
                    <Col lg={5} className='w-lg-36'>
                        <ContactInfoWrapper>
                            <Box mb='20px'>
                                <BodyText textAlign='left' lineHeight='28px' textColor='#fff' fontWeight='400' fontSize='14px' className='text-uppercase letter-space-1px' mb="10px">LET'S TALK</BodyText>
                                <HeadingTitle textColor='#fff'>Speak With Expert Engineers.</HeadingTitle>
                            </Box>
                            <Box>
                                <ContactInfoIconBox>
                                    <ContactInfoIcon>
                                        <FaHome size={20} color='#03228f ' />
                                    </ContactInfoIcon>
                                    <Box>
                                        <BodyText textAlign='left' lineHeight='26px' textColor='#fff' fontWeight='600' fontSize='18px'>Address</BodyText>
                                        <BodyText textAlign='left' lineHeight='26px' textColor='#fff' fontWeight='400' fontSize='16px'>Expert Soft Solution, 74-A, Quaid-e-Azam Town, Gujranwala Pakistan</BodyText>
                                    </Box>
                                </ContactInfoIconBox>
                                <ContactInfoIconBox>
                                    <ContactInfoIcon>
                                        <FaPhone size={20} color='#03228f ' />
                                    </ContactInfoIcon>
                                    <Box>
                                        <BodyText textAlign='left' lineHeight='26px' textColor='#fff' fontWeight='600' fontSize='18px'>Phone</BodyText>
                                        <BodyText textAlign='left' lineHeight='26px' textColor='#fff' fontWeight='400' fontSize='16px'>
                                            <a className='text-white text-decoration-none' href="tel:+923014728930">+92-301-4728-930</a>
                                        </BodyText>
                                    </Box>
                                </ContactInfoIconBox>
                                <ContactInfoIconBox>
                                    <ContactInfoIcon>
                                        <FaEnvelope size={20} color='#03228f ' />
                                    </ContactInfoIcon>
                                    <Box>
                                        <BodyText textAlign='left' lineHeight='26px' textColor='#fff' fontWeight='600' fontSize='18px'>Address</BodyText>
                                        <BodyText textAlign='left' lineHeight='26px' textColor='#fff' fontWeight='400' fontSize='16px'>
                                            <Box>
                                                <a className='text-white text-decoration-none' href="mailto:info@expertsoftsolution.com">info@expertsoftsolution.com</a>
                                            </Box>
                                            <Box>
                                                <a className='text-white text-decoration-none' href="mailto:toakhtar@hotmail.com">toakhtar@hotmail.com</a>
                                            </Box>
                                        </BodyText>
                                    </Box>
                                </ContactInfoIconBox>
                            </Box>
                        </ContactInfoWrapper>
                    </Col>
                    <Col lg={7} className='w-lg-64'>
                        <GetAQuoteFormBox py='30px' px='40px'>
                            <DisplayStart mb='20px' flexDirection='column' verticalAlign='start'>
                                <BodyText textAlign='center' textColor='#e47226' fontWeight='400' fontSize='14px' className='text-uppercase letter-space-1px' mb="10px">GET IN TOUCH</BodyText>
                                <HeadingTitle textAlign='center'>Get A Quote</HeadingTitle>
                                <DotLine />
                            </DisplayStart>
                            <GetAQuoteForm inputBackground="#f6f7f9" />
                        </GetAQuoteFormBox>
                    </Col>

                </Row>
            </Container>
            <Box mt='50px' className='map-mb-fix'>
                <iframe loading="lazy" src="https://maps.google.com/maps?q=Expert%20Soft%20Solution%2C%2074A%2C%20Quaid-e-Azam%20Town%2C%20Gujranwala&amp;t=m&amp;z=10&amp;output=embed&amp;iwloc=near" title="Expert Soft Solution, 74A, Quaid-e-Azam Town, Gujranwala" aria-label="Expert Soft Solution, 74A, Quaid-e-Azam Town, Gujranwala" width="100%" height="450"></iframe>
            </Box>
        </>
    );
}
