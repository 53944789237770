import React, { FC } from 'react';
import { Col, Row } from 'react-bootstrap';
import { BodyText, Box, DisplayStart, DotLine, HeadingTitle } from '../../../components/styledComponents';
import { BorderLineVertical } from '../../../components/styledComponents2';

interface IProps {
}

export const MobileAppDevSection: FC<IProps> = ({ }) => {
    return (
        <>
            <Row>
                <Col lg={6} sm={12} className='align-self-start'>
                    <Box className='pt-2'>
                        <DisplayStart className='align-items-start' pb="12px">
                            <Box><BorderLineVertical /></Box>
                            <Box>
                                <BodyText textColor='#454545' fontSize='20px' fontWeight='700' mb="15px" pl="25px">
                                    Native Mobile App Development
                                </BodyText>
                            </Box>
                        </DisplayStart>
                        <Box mb='28px'>
                            <BodyText textColor='#454545' fontSize='18px' fontWeight='500' mb="25px">
                                Building a native app allows you to hook into unique functionalities of a device. Gaining high elasticity in development, and the ability to easily implement existing native libraries are the core characteristics of native development.
                            </BodyText>
                            <Box mb="20px">
                                <img className="custom-software-img" src="./images/services/mobile-app-development/ios-logo.webp" alt="ios technology logo - Expert Soft Solution" />
                            </Box>
                            <Box mb="20px">
                                <img className="custom-software-img" src="./images/services/mobile-app-development/andriod-logo.webp" alt="andriod technology logo - Expert Soft Solution" />
                            </Box>
                        </Box>
                    </Box>
                </Col>
                <Col lg={6} sm={12} className='align-self-start'>
                    <Box className='pt-2'>
                        <DisplayStart className='align-items-start' pb="12px">
                            <Box><BorderLineVertical /></Box>
                            <Box>
                                <BodyText textColor='#454545' fontSize='20px' fontWeight='700' mb="15px" pl="25px">
                                    Hybrid Mobile App Development
                                </BodyText>
                            </Box>
                        </DisplayStart>
                        <Box mb='14px'>
                            <BodyText textColor='#454545' fontSize='18px' fontWeight='500' mb="25px">
                                Creating a hybrid app means that you’re building components for various platforms in one go (Android, iOS). Hybrid mobile applications accelerate businesses from startups to large enterprises and offer great optimization for your company.
                            </BodyText>
                            <Box mb="20px">
                                <img className="custom-software-img" src="./images/services/mobile-app-development/flutter-logo.webp" alt="flutter technology logo - Expert Soft Solution" />
                            </Box>
                            <Box mb="20px">
                                <img className="custom-software-img" src="./images/services/mobile-app-development/xamarin-logo.webp" alt="xamarin technology logo - Expert Soft Solution" />
                            </Box>
                            <Box>
                                <img className="custom-software-img" src="./images/services/mobile-app-development/react-native-logo.webp" alt="react native technology logo - Expert Soft Solution" />
                            </Box>
                        </Box>
                    </Box>
                </Col>
            </Row>
        </>
    );
}