import React, { FC } from 'react';
import { PageBanner } from '../../../components/pageBanner';
import { FooterTop } from '../../../components/footerTop';
import { Col, Container, Row } from 'react-bootstrap';
import { BodyText, Box, DotLine, HeadingTitle, TesxtRightWrapper, TitleDescription } from '../../../components/styledComponents';
import { MainSection } from './mainSection';
import { IconBoxSection } from './iconBoxSection';

interface IProps {
}

export const CustomSoftwareDevelopment: FC<IProps> = ({ }) => {
    return (
        <>
            <PageBanner
                bannerImg="./images/services/custom-software-development/custom-software-development-banner.webp"
                pageTitle="Expert Soft Solution / Custom Software Development"
            />
            <Container className='pt-5 pb-3'>
                <MainSection />
                <IconBoxSection />
            </Container>
            <FooterTop subTitle="Plan to Start a Project" title="Our Experts Ready to Help You" btnText="Contact Us" />
        </>
    );
}
