import React, { lazy, Suspense } from 'react';
import './App.min.css';
import { Routes, Route } from 'react-router-dom';
import { Routes as MyRoutes } from '../routes/routes';
import { ToastContainer } from 'react-toastify';

import { HomeLayout } from '../layout/homeLayout';
import { Home } from '../pages/home/index';
import { MainLayout } from '../layout/mainLayout';
import { About } from '../pages/about';
import { CustomSoftwareDevelopment } from '../pages/services/customSoftwareDevelopment';
import { WebAppDevelopment } from '../pages/services/webAppDevelopment';
import { CloudAndDevOps } from '../pages/services/cloudAndDevOps';
import { QualityAssurance } from '../pages/services/qualityAssurance';
import { MobileAppDevelopment } from '../pages/services/mobileAppDevelopment';
import { ProductEngineering } from '../pages/services/productEngineering';
import { DatabaseAdministration } from '../pages/services/databaseAdministration';
import { WebsiteSecurity } from '../pages/services/WebsiteSecurity';
import { AspNetWebsites } from '../pages/projects/aspNetWebsites';
import { WordpressWebsites } from '../pages/projects/wordpressWebsites';
import { PhpWebsites } from '../pages/projects/phpWebsites';
import { WebApplication } from '../pages/projects/webApplication';
import { Technologies } from '../pages/company/technologies';
import { Career } from '../pages/company/career';
import { OurProcess } from '../pages/company/ourProcess/index';
import { OutsourcingToPakistan } from '../pages/company/outsourcingToPakistan';
import { WebDesigns } from '../pages/designs/webDesigns';
import { MobileApps } from '../pages/designs/mobileApps';
import { GraphicsDesign } from '../pages/designs/graphicsDesign';
import { AldonApp } from '../pages/designs/mobileApps/aldon';
import { FabrioApp } from '../pages/designs/mobileApps/fabrio';
import { FootBallApp } from '../pages/designs/mobileApps/footBall';
import { SparkyApp } from '../pages/designs/mobileApps/sparky';
import { LogoDesigns } from '../pages/designs/logoDesigns';
import { Flyer } from '../pages/designs/flyer';
import { Brochures } from '../pages/designs/brochures';
import { CorporateIdentity } from '../pages/designs/corporateIdentity';
import { Contact } from '../pages/contact';
import { CyberSecurity } from '../pages/services/cyberSecurity';

// const HomeLayout = lazy(() => import('../layout/homeLayout').then(module => ({ default: module.HomeLayout })));
// const Home = lazy(() => import('../pages/home/index').then(module => ({ default: module.Home })));
// const MainLayout = lazy(() => import('../layout/mainLayout').then(module => ({ default: module.MainLayout })));
// const About = lazy(() => import('../pages/about').then(module => ({ default: module.About })));
// const CustomSoftwareDevelopment = lazy(() => import('../pages/services/customSoftwareDevelopment').then(module => ({ default: module.CustomSoftwareDevelopment })));
// const WebAppDevelopment = lazy(() => import('../pages/services/webAppDevelopment').then(module => ({ default: module.WebAppDevelopment })));
// const CloudAndDevOps = lazy(() => import('../pages/services/cloudAndDevOps').then(module => ({ default: module.CloudAndDevOps })));
// const QualityAssurance = lazy(() => import('../pages/services/qualityAssurance').then(module => ({ default: module.QualityAssurance })));
// const MobileAppDevelopment = lazy(() => import('../pages/services/mobileAppDevelopment').then(module => ({ default: module.MobileAppDevelopment })));
// const ProductEngineering = lazy(() => import('../pages/services/productEngineering').then(module => ({ default: module.ProductEngineering })));
// const DatabaseAdministration = lazy(() => import('../pages/services/databaseAdministration').then(module => ({ default: module.DatabaseAdministration })));
// const WebsiteSecurity = lazy(() => import('../pages/services/WebsiteSecurity').then(module => ({ default: module.WebsiteSecurity })));
// const AspNetWebsites = lazy(() => import('../pages/projects/aspNetWebsites').then(module => ({ default: module.AspNetWebsites })));
// const WordpressWebsites = lazy(() => import('../pages/projects/wordpressWebsites').then(module => ({ default: module.WordpressWebsites })));
// const PhpWebsites = lazy(() => import('../pages/projects/phpWebsites').then(module => ({ default: module.PhpWebsites })));
// const WebApplication = lazy(() => import('../pages/projects/webApplication').then(module => ({ default: module.WebApplication })));
// const Technologies = lazy(() => import('../pages/company/technologies').then(module => ({ default: module.Technologies })));
// const Career = lazy(() => import('../pages/company/career').then(module => ({ default: module.Career })));
// const OurProcess = lazy(() => import('../pages/company/ourProcess/index').then(module => ({ default: module.OurProcess })));
// const OutsourcingToPakistan = lazy(() => import('../pages/company/outsourcingToPakistan').then(module => ({ default: module.OutsourcingToPakistan })));
// const WebDesigns = lazy(() => import('../pages/designs/webDesigns').then(module => ({ default: module.WebDesigns })));
// const MobileApps = lazy(() => import('../pages/designs/mobileApps').then(module => ({ default: module.MobileApps })));
// const GraphicsDesign = lazy(() => import('../pages/designs/graphicsDesign').then(module => ({ default: module.GraphicsDesign })));
// const AldonApp = lazy(() => import('../pages/designs/mobileApps/aldon').then(module => ({ default: module.AldonApp })));
// const FabrioApp = lazy(() => import('../pages/designs/mobileApps/fabrio').then(module => ({ default: module.FabrioApp })));
// const FootBallApp = lazy(() => import('../pages/designs/mobileApps/footBall').then(module => ({ default: module.FootBallApp })));
// const SparkyApp = lazy(() => import('../pages/designs/mobileApps/sparky').then(module => ({ default: module.SparkyApp })));
// const LogoDesigns = lazy(() => import('../pages/designs/logoDesigns').then(module => ({ default: module.LogoDesigns })));
// const Flyer = lazy(() => import('../pages/designs/flyer').then(module => ({ default: module.Flyer })));
// const Brochures = lazy(() => import('../pages/designs/brochures').then(module => ({ default: module.Brochures })));
// const CorporateIdentity = lazy(() => import('../pages/designs/corporateIdentity').then(module => ({ default: module.CorporateIdentity })));
// const Contact = lazy(() => import('../pages/contact').then(module => ({ default: module.Contact })));


function App() {
  return (
    <div className="App">
      <ToastContainer limit={2} newestOnTop theme='light' />
      <Routes>
        <Route element={<HomeLayout />}>
          <Route path={MyRoutes.home} element={<Home />} />
        </Route>
        <Route element={<MainLayout />}>
          <Route path={MyRoutes.about} element={<About />} />
          <Route path={MyRoutes.customSoftwareDevelopment} element={<CustomSoftwareDevelopment />} />
          <Route path={MyRoutes.webAppDevelopment} element={<WebAppDevelopment />} />
          <Route path={MyRoutes.cloudAndDevOps} element={<CloudAndDevOps />} />
          <Route path={MyRoutes.qualityAssurance} element={<QualityAssurance />} />
          <Route path={MyRoutes.mobileAppDevelopment} element={<MobileAppDevelopment />} />
          <Route path={MyRoutes.productEngineering} element={<ProductEngineering />} />
          <Route path={MyRoutes.databaseAdministration} element={<DatabaseAdministration />} />
          <Route path={MyRoutes.webSecurity} element={<WebsiteSecurity />} />
          <Route path={MyRoutes.cyberSecurity} element={<CyberSecurity />} />
          <Route path={MyRoutes.aspNetWebsites} element={<AspNetWebsites />} />
          <Route path={MyRoutes.wordpressWebsites} element={<WordpressWebsites />} />
          <Route path={MyRoutes.phpWebsites} element={<PhpWebsites />} />
          <Route path={MyRoutes.webApplication} element={<WebApplication />} />
          <Route path={MyRoutes.technologies} element={<Technologies />} />
          <Route path={MyRoutes.career} element={<Career />} />
          <Route path={MyRoutes.ourProcess} element={<OurProcess />} />
          <Route path={MyRoutes.outsourcingToPakistan} element={<OutsourcingToPakistan />} />
          <Route path={MyRoutes.webDesigns} element={<WebDesigns />} />
          <Route path={MyRoutes.mobileApps} element={<MobileApps />} />
          <Route path={MyRoutes.graphicsDesigns} element={<GraphicsDesign />} />
          <Route path={MyRoutes.aldonApp} element={<AldonApp />} />
          <Route path={MyRoutes.fabrioApp} element={<FabrioApp />} />
          <Route path={MyRoutes.footBallApp} element={<FootBallApp />} />
          <Route path={MyRoutes.sparkyApp} element={<SparkyApp />} />
          <Route path={MyRoutes.logoDesigns} element={<LogoDesigns />} />
          <Route path={MyRoutes.flyer} element={<Flyer />} />
          <Route path={MyRoutes.brochures} element={<Brochures />} />
          <Route path={MyRoutes.corporateIdentity} element={<CorporateIdentity />} />
          <Route path={MyRoutes.contac} element={<Contact />} />
        </Route>
      </Routes>
    </div>
  );
}

export default App;
