import React, { FC } from 'react';
import { PageBanner } from '../../../components/pageBanner';
import { Container } from 'react-bootstrap';
import { FooterTop } from '../../../components/footerTop';
import { MainSection } from './mainSection';
import { IconBoxSection } from './iconBoxSection';
import { MobileAppDevSection } from './mobileAppDevSection';

interface IProps {
}

export const MobileAppDevelopment: FC<IProps> = ({ }) => {
    return (
        <>
            <PageBanner
                bannerImg="./images/services/mobile-app-development/mobile-app-development-banner.webp"
                pageTitle="Expert Soft Solution / Mobile App Development"
            />
            <Container className='pt-5 pb-3'>
                <MainSection />
                <IconBoxSection />
                <MobileAppDevSection />
            </Container>
            <FooterTop subTitle="Plan to Start a Project" title="Our Experts Ready to Help You" btnText="Contact Us" />
        </>
    );
}
